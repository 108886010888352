import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { dayjs } from './dayjs';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly _document = inject(DOCUMENT);
  setCookie(
    cookieName: string,
    cookieValue: string,
    expiresMinutes: number
  ): void {
    const expires = new Date(
      dayjs().add(expiresMinutes, 'minute').toISOString()
    ).toUTCString();
    this._document.cookie = `${cookieName}=${cookieValue}; expires=${expires}; path=/; Secure`;
  }

  getCookie(cookieName: string): string | null {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(this._document.cookie);
    const ca = decodedCookie.split(';');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  deleteCookie(cookieName: string): void {
    const expires = new Date(
      dayjs().subtract(1, 'day').toISOString()
    ).toUTCString();
    this._document.cookie = `${cookieName}=; expires=${expires}; path=/; Secure`;
  }
}
