import { computed, inject } from '@angular/core';
import { Params } from '@angular/router';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { FlashyUser } from '@flashy/users/domain';
import { CookieService } from '@flashy/utils';

import { FLASHY_AUTH_HASH, FlashyTokenService } from '../flashy-token.service';
import { SignInPayload } from '../payloads/sign-in.payload';
import { UserDataService } from '../user-data.service';

type UserStore = {
  user: FlashyUser | null;
  isLoading: boolean;
  userAction?: 'signIn' | '2fa' | 'resend2fa' | 'signUp' | 'forgot' | 'reset';
};

const initialState: UserStore = {
  user: null,
  isLoading: false,
  userAction: undefined,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  withState<UserStore>(initialState),
  withMethods((store) => {
    const service = inject(UserDataService);
    const tokenService = inject(FlashyTokenService);
    const cookieService = inject(CookieService);
    const recaptchaService = inject(ReCaptchaV3Service);
    return {
      getUser: async () => {
        if (store.user()) {
          return store.user();
        }

        patchState(store, {
          isLoading: true,
        });

        const { data: user } = await firstValueFrom(service.getUser());

        patchState(store, {
          isLoading: false,
          user,
        });

        return user;
      },
      changePassword: async (payload: Record<string, string>) => {
        patchState(store, {
          isLoading: true,
        });

        await firstValueFrom(service.changePassword(payload));

        patchState(store, {
          isLoading: false,
        });
      },
      update: async (payload: Partial<FlashyUser>) => {
        patchState(store, {
          isLoading: true,
        });

        const { data: user } = await firstValueFrom(service.update(payload));

        patchState(store, {
          isLoading: false,
          user,
        });

        return user;
      },
      verifyAuthentication: async (code: string) => {
        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const hash = cookieService.getCookie(FLASHY_AUTH_HASH) as string;
        const token = await lastValueFrom(recaptchaService.execute('login'));

        try {
          const { data: value } = await firstValueFrom(
            service.verify({
              hash,
              code,
              token,
            })
          );

          tokenService.token = value.token;

          if (value.token) {
            cookieService.deleteCookie(FLASHY_AUTH_HASH);
          }

          return value.token;
        } finally {
          patchState(store, {
            isLoading: false,
            userAction: '2fa',
          });
        }
      },
      resendVerification: async () => {
        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const hash = cookieService.getCookie(FLASHY_AUTH_HASH) as string;
        const token = await lastValueFrom(recaptchaService.execute('login'));

        try {
          const { data: value } = await firstValueFrom(
            service.resendVerification({
              hash,
              token,
            })
          );

          cookieService.setCookie(FLASHY_AUTH_HASH, value.hash, 8);

          return value.hash;
        } finally {
          patchState(store, {
            isLoading: false,
            userAction: 'resend2fa',
          });
        }
      },
      signIn: async (payload: SignInPayload) => {
        cookieService.deleteCookie(FLASHY_AUTH_HASH);

        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const { data: value } = await firstValueFrom(service.signIn(payload));

        cookieService.setCookie(FLASHY_AUTH_HASH, value.hash, 8);

        patchState(store, {
          isLoading: false,
          userAction: 'signIn',
        });

        return value.hash;
      },
      signUp: async (payload: Params) => {
        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const { data: value } = await firstValueFrom(service.signUp(payload));

        patchState(store, {
          isLoading: false,
          userAction: 'signUp',
        });

        cookieService.setCookie(FLASHY_AUTH_HASH, value.hash, 8);

        return value.hash;
      },
      signOut: async () => {
        patchState(store, {
          isLoading: true,
        });

        await firstValueFrom(service.signOut());

        patchState(store, {
          isLoading: false,
        });

        tokenService.clear();
      },
      forgotPassword: async (payload: Params) => {
        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const { data: value } = await firstValueFrom(service.forgot(payload));

        patchState(store, {
          isLoading: false,
          userAction: 'forgot',
        });

        return value;
      },
      resetPassword: async (payload: Params) => {
        patchState(store, {
          isLoading: true,
          userAction: undefined,
        });

        const { data: value } = await firstValueFrom(service.reset(payload));

        patchState(store, {
          isLoading: false,
          userAction: 'reset',
        });

        return value;
      },
      needAuthentication: () => {
        return Boolean(cookieService.getCookie(FLASHY_AUTH_HASH));
      },
      isAuthorized: () => Boolean(tokenService.token),
    };
  }),
  withComputed((store) => ({
    userAvatar: computed(
      () =>
        `https://www.gravatar.com/avatar/${store.user()?.gravatar}?s=50&d=mp`
    ),
  }))
);
