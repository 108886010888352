import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { FlashyUser } from '@flashy/users/domain';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { IFlashyServerResponse } from '@shared/domain';
import { Observable } from 'rxjs';

import { ResendVerificationPayload } from './payloads/resend-verification.payload';
import { SignInPayload } from './payloads/sign-in.payload';
import { VerifyPayload } from './payloads/verify.payload';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private httpClient = inject(HttpClient);
  private url = inject(DefaultDataServiceConfig);

  getUser(): Observable<IFlashyServerResponse<FlashyUser>> {
    return this.httpClient.get<IFlashyServerResponse<FlashyUser>>(
      `${this.url.root}/user`
    );
  }

  changePassword(payload: Record<string, string>): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url.root}/change-password`,
      payload
    );
  }

  // TODO: add to cache
  update(
    payload: Partial<FlashyUser>
  ): Observable<IFlashyServerResponse<FlashyUser>> {
    return this.httpClient.put<IFlashyServerResponse<FlashyUser>>(
      `${this.url.root}/user`,
      payload
    );
  }

  signIn(
    payload: SignInPayload
  ): Observable<IFlashyServerResponse<{ hash: string }>> {
    return this.httpClient.post<IFlashyServerResponse<{ hash: string }>>(
      `${this.url.root}/auth`,
      payload.params
    );
  }

  verify(
    payload: VerifyPayload
  ): Observable<IFlashyServerResponse<{ token: string }>> {
    return this.httpClient.post<IFlashyServerResponse<{ token: string }>>(
      `${this.url.root}/verify`,
      payload
    );
  }

  resendVerification(
    payload: ResendVerificationPayload
  ): Observable<IFlashyServerResponse<{ hash: string }>> {
    return this.httpClient.post<IFlashyServerResponse<{ hash: string }>>(
      `${this.url.root}/resend`,
      payload
    );
  }

  signUp(payload: Params): Observable<IFlashyServerResponse<{ hash: string }>> {
    return this.httpClient.post<IFlashyServerResponse<{ hash: string }>>(
      `${this.url.root}/signup`,
      payload
    );
  }

  signOut(): Observable<unknown> {
    return this.httpClient.post<unknown>(`${this.url.root}/signout`, {});
  }

  forgot(params: Params): Observable<IFlashyServerResponse<string>> {
    return this.httpClient.post<IFlashyServerResponse<string>>(
      `${this.url.root}/forgot-password`,
      params
    );
  }

  reset(params: Params): Observable<IFlashyServerResponse<unknown>> {
    return this.httpClient.post<IFlashyServerResponse<unknown>>(
      `${this.url.root}/reset-password`,
      params
    );
  }
}
